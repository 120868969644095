import React from 'react'
import DownloadBtn from '../Includes/DownloadBtn/DownloadBtn'
import adsHero from "../../img/home/adsHero.webp"
import indianImg from '../../img/home/indianImg.webp'
import TopFooter from '../Includes/Footer/TopFooter'
import mission from '../../img/home/mission.png'
import vision from '../../img/home/vision.png'

const HomeBody = () => {
    return (
        <>
            <div className='visionMissionBg'>
                <div className='container'>
                    <div className='row visionMissionBox'>
                        <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='visionMission'>
                                <h6>Our Mission</h6>
                                <p>“The mission of BizsApp is to provide a platform for professionals and business owners to connect, collaborate, and succeed by sharing knowledge, resources, and opportunities.”</p>
                            </div>
                        </div>
                        <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='visionMissionImg one'>
                                <img src={mission} alt="mission" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12' id="order2">
                            <div className='visionMissionImg'>
                                <img src={vision} alt="vision" className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12' id="order1">
                            <div className='visionMission one'>
                                <h6>Our Vision</h6>
                                <p>“BizsApp's vision is to be a catalyst for innovation, collaboration, and success for professionals and business owners worldwide.”</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='homeBodyBg'>
                <div className='container'>
                    <div className='homeAdsBg'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12' id='order2'>
                                <div className='homeAdsImg'>
                                    <img src={adsHero} alt="adsHero" className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12' id='order1'>
                                <div className='homeAdsContent'>
                                    <h4>It's easy to grow online with BizsApp</h4>
                                    <p>we have made it so simple & easy to use. so that, everyone
                                        can use it easily & can grow their businesses online with the
                                        help of its powerful tools and features. </p>
                                    {/* <div className='homeAdsBtn'>
                                        <DownloadBtn />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title'>
                                <h2>What is BizsApp?</h2>
                            </div>
                            <div className='homeVideo'>
                                <iframe src="https://www.youtube.com/embed/cS1qT8mWdqs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TopFooter />
            <div className='indianBg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='indianText'>
                                <h3>Proudly Created by </h3>
                                <h2>indians</h2>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='indianImg'>
                                <img src={indianImg} alt="indianImg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeBody