const firstBox = [
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },

]

const secondBox = [
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
]

const thirdBox = [
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },
    {
        src: "PRODUCT_SELLERS",
        name: "PRODUCT_SELLERS"

    },
    {
        src: "SERVICE_SELLERS",
        name: "SERVICE_SELLERS"

    },
    {
        src: "INDIVIDUALS",
        name: "INDIVIDUALS"

    },
    {
        src: "SELF_EMPLOYED",
        name: "SELF_EMPLOYED"

    },
    {
        src: "DIGITAL_CREATORS",
        name: "DIGITAL_CREATORS"

    },
    {
        src: "INFLUENCERS",
        name: "INFLUENCERS"

    },
    {
        src: "FREELANCERS",
        name: "FREELANCERS"

    },
    {
        src: "STARTUPS",
        name: "STARTUPS"

    },
    {
        src: "BRANDS_&_BUSINESSES",
        name: "BRANDS_&_BUSINESSES"

    },
    {
        src: "LOCAL_BUSINESSES",
        name: "LOCAL_BUSINESSES"

    },

]

export { firstBox, secondBox, thirdBox }