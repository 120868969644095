import React from 'react'
import { Routes, Route } from 'react-router-dom'
import UserCard from '../Pages/BMC/UserCard'
import Home from '../Pages/Home'
import ScrollToTop from '../Pages/ScrollToTop'
import ProductShare from '../Pages/SharePage/ProductShare'
import ServiceShare from '../Pages/SharePage/ServiceShare'

const Routers = () => {
    return (
        <>
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/BMC/usercard/:id" element={<UserCard />} />
                    <Route path="/product/:id" element={<ProductShare />} />
                    <Route path="/service/:id" element={<ServiceShare />} />
                </Routes>
            </ScrollToTop>
        </>
    )
}

export default Routers